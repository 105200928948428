import { axiosCreate } from '@/services/Api'
import store from '@/state/store'
import dayjs from 'dayjs'

export default async function({ date }) {
  const result = await axiosCreate.get(`/custbusinessdayhourcheck`, {
    params: {
      date: dayjs(date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    },
    headers: { authorization: store.getters.token }
  })

  return result.data
}
