<template>
  <b-row>
    <b-col cols="12">
      <b-card v-if="item.MediumDescription || item.LongDescription">
        <h3>Details</h3>
        <p v-html="item.MediumDescription" />
        <p v-html="item.LongDescription" />
      </b-card>
    </b-col>
    <b-col
      v-for="item in items"
      :key="item.ItemID"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="mt-4"
    >
      <h4>
        {{ item.Description }}
        <span v-if="itemType != 'RentalPeriod' && itemType != 'item'">
          - {{ item.Amount }} {{ item.Amount > 1 ? 'stuks' : 'stuk' }}</span
        >
      </h4>

      <b-card>
        <item-single-content
          :item="item"
          :item-type="itemType"
          :composition-item-amount="item.compositionItemAmount"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ItemSingleContent from './SingleItemContent'
import { getItem } from '@/services/ItemService'
export default {
  components: {
    'item-single-content': ItemSingleContent
  },
  props: {
    items: {
      required: true,
      type: Array
    },
    item: {
      required: true,
      type: Object
    },
    itemType: {
      required: true,
      type: String,
      default: function() {
        return 'item'
      }
    }
  },
  data: function() {
    return {
      compositionItems: []
    }
  },
  created: async function() {
    // for (let item of this.items) {
    //   await this.getItem(item.ID ? item.ID : item.ItemID, item.Amount)
    // }
  },
  methods: {
    getItem: async function(itemID, compositionItemAmount) {
      const item = await getItem({ itemID })
      item.compositionItemAmount = compositionItemAmount
      this.compositionItems.push(item)
    }
  }
}
</script>

<style>
h3 {
  font-size: 24px;
}
</style>
