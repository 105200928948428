<template>
  <div class="item-information">
    <b-row>
      <b-col sm="12" lg="3">
        <image-grid v-if="compositionImages" :images="compositionImages" />

        <item-image-slider
          v-if="images.length > 0 && compositionImages === null"
          :images="item.Images"
          :item-slug="item.Slug"
        />

        <b-img
          v-if="
            !imageUrl &&
              item.Images &&
              item.Images.length < 2 &&
              compositionImages === null
          "
          fluid
          class="d-block mx-auto"
          src="/img/itemFallback.png"
        />
      </b-col>
      <b-col sm="12" lg="9">
        <div
          v-if="item.LongDescription || item.MediumDescription"
          class="item-description"
        >
          <h4>Details</h4>
          <p v-html="item.MediumDescription" />
          <p v-html="item.LongDescription" />
        </div>

        <single-item-content-properties :item="item" />

        <single-item-content-links v-if="attachments.length > 0" :item="item" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ItemImageSlider from './ImageSlider/ItemImageSlider'
import SingleItemContentProperties from './SingleItemContentProperties'
import SingleItemContentLinks from './SingleItemContentLinks'
import ImageGrid from '@/components/items/ImageGrid'
export default {
  components: {
    'item-image-slider': ItemImageSlider,
    'single-item-content-links': SingleItemContentLinks,
    'single-item-content-properties': SingleItemContentProperties,
    'image-grid': ImageGrid
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    itemType: {
      required: false,
      type: String,
      default: function() {
        return 'item'
      }
    }
  },
  computed: {
    images() {
      if (this.item.Images?.length) {
        return this.item.Images
      }
      return []
    },
    attachments() {
      if (this.item.Type === 'Composition') {
        const attachments = []

        //loop through items in this.item.Items and add all ItemAttachments to attachments
        this.item.Items.forEach(item => {
          item.ItemAttachments.forEach(attachment => {
            attachments.push(attachment)
          })
        })
        return attachments
      }
      return this.item.ItemAttachments
    },
    compositionImages: function() {
      if (this.item.Items) {
        let filteredImages = this.item.Items.filter(function(item) {
          return item.DefaultImage
        })
        let images = filteredImages.map(
          item =>
            process.env.VUE_APP_URL +
            (item.DefaultImage ? item.DefaultImage.ImageUrl : '')
        )
        return images
      }
      return null
    },
    imageUrl: function() {
      if (
        this.item.Images &&
        this.item.Images != undefined &&
        this.item.Images[0]
      ) {
        return `${process.env.VUE_APP_URL}${this.item.Images[0].ImageUrl}`
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.item-description {
  p,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  i {
    font-family: 'Roboto' !important;
  }
}
.fileMeta {
  color: #015ea5;
  font-size: 18px;
  font-family: 'DecimaPro-bold';
}
p {
  font-family: 'Roboto';

  ul {
    li {
      font-size: 18px !important;
      font-family: 'Roboto' !important;
    }
  }
}
ul {
  padding-left: 2px;
  a {
    font-size: 18px;
    font-family: 'DecimaPro-bold';
  }
  .link {
    font-size: 14px;
  }
}
</style>
