<template>
  <div v-if="properties.length > 0">
    <h4>Eigenschappen</h4>
    <table class="table">
      <template v-for="property in properties">
        <span :key="property.key">
          <tr class="table d-none d-md-block">
            <td>{{ property.title }}</td>
            <td v-if="property.type === 'string'">{{ property.value }}</td>
            <td v-if="property.type === 'number'">{{ property.value }}</td>
            <td v-if="property.type === 'decimal'">
              {{ parseDecimal(property.value) }}
            </td>
            <td v-if="property.type === 'boolean'">
              {{ property.value ? 'Ja' : 'Nee' }}
            </td>
          </tr>

          <tr class="table d-block d-md-none">
            <td>
              <strong>{{ property.title }}</strong>
            </td>
          </tr>
          <tr class="table d-block d-md-none">
            <td v-if="property.type === 'string'">{{ property.value }}</td>
            <td v-if="property.type === 'number'">{{ property.value }}</td>
            <td v-if="property.type === 'decimal'">
              {{ parseDecimal(property.value) }}
            </td>
            <td v-if="property.type === 'boolean'">
              {{ property.value ? 'Ja' : 'Nee' }}
            </td>
          </tr>
        </span>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data: function() {
    return {
      properties: []
    }
  },
  created: function() {
    if (this.item.Dimensions)
      this.addProperty({ key: 'Dimensions', value: this.item.Dimensions })
    if (this.item.ShippingWeight)
      this.addProperty({
        key: 'ShippingWeight',
        value: this.item.ShippingWeight
      })
    if (this.item.CUST_Difficulty)
      this.addProperty({
        key: 'CUST_Difficulty',
        value: this.item.CUST_Difficulty
      })
    if (this.item.CUST_IndoorAndOutdoorUse !== null)
      this.addProperty({
        key: 'CUST_IndoorAndOutdoorUse',
        value: this.item.CUST_IndoorAndOutdoorUse
      })
    if (this.item.CUST_Transport)
      this.addProperty({
        key: 'CUST_Transport',
        value: this.item.CUST_Transport
      })
  },
  methods: {
    addProperty({ key, value }) {
      if (value === null || value === '') return
      if (key === 'Dimensions')
        this.properties.push({
          key,
          title: 'Afmetingen materiaal',
          value: `${value} cm`,
          type: 'string'
        })
      if (key === 'ShippingWeight')
        this.properties.push({
          key,
          title: 'Gewicht materiaal',
          value: `${value} kg`.replace('.', ','),
          type: 'string'
        })
      if (key === 'CUST_Difficulty')
        this.properties.push({
          key,
          title: 'Moeilijkheidsgraad',
          value: `${value}/10`,
          type: 'string'
        })
      if (key == 'CUST_IndoorAndOutdoorUse') {
        this.properties.push({
          key,
          title: 'Geschikt voor buitengebruik',
          value: value,
          type: 'boolean'
        })
      }
      if (key === 'CUST_Transport') {
        this.properties.push({
          key,
          title: 'Vervoersmiddel',
          value,
          type: 'string'
        })
      }
    },
    parseDecimal(number) {
      return parseInt(number)
        .toFixed(1)
        .replace('.', ',')
    }
  }
}
</script>

<style scoped lang="scss">
div {
  svg {
    margin-left: -5px;
  }
  .table {
    background-color: transparent;
    tr,
    td {
      border: none !important;
      padding-left: 0;
      margin-left: 0;
      font-size: 16px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      font-family: 'Roboto' !important;
    }
    span {
      td {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
