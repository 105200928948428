<template>
  <div
    v-if="priceDifference > 0 && priceDifference != currentPrice"
    class="date-suggestion"
  >
    <hr />
    <div>
      <info-icon />
    </div>
    <div>
      <p v-if="currentEndDay === 'vrijdag'">
        Tip!
        <strong>Bespaar {{ sanitizedPriceDifference }} euro</strong>
        indien je terugbrengt op {{ recommendedDay }} in plaats van
        {{ currentEndDay }}.
      </p>
      <p v-else>
        Tip!
        <strong>Bespaar {{ sanitizedPriceDifference }} euro</strong>
        indien je ophaalt op {{ recommendedDay }} in plaats van
        {{ currentStartDay }}.
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

import { getItem } from '@/services/ItemService'

export default {
  props: {
    startDate: {
      required: true,
      type: String,
      default: function() {
        return null
      }
    },
    endDate: {
      required: true,
      type: String,
      default: function() {
        return null
      }
    },
    warehouse: {
      required: true,
      type: String,
      default: function() {
        return null
      }
    },
    currentPrice: {
      required: true,
      type: Number,
      default: function() {
        return 0
      }
    }
  },
  data: function() {
    return {
      days: [
        'zaterdag',
        'maandag',
        'dinsdag',
        'woensdag',
        'donderdag',
        'vrijdag'
      ],

      suggestedDateItemData: null,
      recommendedDate: null
    }
  },
  computed: {
    recommendedDay: function() {
      if (this.recommendedDate)
        return this.getDayFromDate({
          date: this.recommendedDate,
          format: 'DD-MM-YYYY'
        })

      return null
    },
    currentStartDay: function() {
      return this.getDayFromDate({ date: this.startDate, format: 'DD/MM/YYYY' })
    },
    currentEndDay: function() {
      return this.getDayFromDate({ date: this.endDate, format: 'DD/MM/YYYY' })
    },

    suggestedItemDateWarehouseData: function() {
      if (this.suggestedDateItemData) {
        return this.suggestedDateItemData.filter(warehouse => {
          return warehouse.WarehouseID === this.warehouse
        })[0]
      }
      return null
    },
    priceDifference: function() {
      if (!this.suggestedItemDateWarehouseData) return null
      if (!this.suggestedItemDateWarehouseData.Price < this.currentPrice) {
        return this.currentPrice - this.suggestedItemDateWarehouseData.Price
      }
      return null
    },
    sanitizedPriceDifference: function() {
      if (!this.priceDifference) return null
      return this.formatPrice(this.priceDifference)
    }
  },
  watch: {
    currentStartDay: function() {
      this.refreshPriceSuggestions()
    },
    currentEndDay: function() {
      this.refreshPriceSuggestions()
    }
  },
  created: function() {
    this.refreshPriceSuggestions()
  },
  methods: {
    refreshPriceSuggestions: async function() {
      if (this.currentStartDay === 'maandag') {
        // Get monday data
        const newStartDate = dayjs(this.startDate, 'DD/MM/YYYY')
          .add(2, 'days')
          .format('DD-MM-YYYY')
        await this.getItemData({
          startDate: newStartDate,
          endDate: this.endDate
        })
        this.recommendedDate = newStartDate
      }
      if (this.currentEndDay === 'vrijdag') {
        // Get thursday data
        const newEndDate = dayjs(this.endDate, 'DD/MM/YYYY')
          .subtract(1, 'days')
          .format('DD-MM-YYYY')
        this.recommendedDate = newEndDate
        await this.getItemData({
          startDate: this.startDate,
          endDate: newEndDate
        })
      }
    },
    getDayFromDate({ date, format }) {
      const dow = dayjs(date, format).day()

      return this.days[dow]
    },
    getItemData: async function({ startDate, endDate }) {
      const result = await getItem({
        itemID: this.$route.params.itemID,
        startDate: startDate,
        endDate: endDate,
        slug: true
      })

      this.suggestedDateItemData = result.Warehouses
    },
    formatPrice: function(price) {
      if (!price) return null
      return (Math.round(price * 100) / 100).toFixed(2).replace('.', ',')
    }
  }
}
</script>

<style scoped lang="scss">
.date-suggestion {
  display: flex;
  flex-direction: row;
  svg {
    vertical-align: top;
    margin-right: 6px;
  }
  div {
    display: inline-flex;
  }
  p,
  svg,
  strong {
    color: #015ea5;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
</style>
